import { RadioGroup } from "@headlessui/react";
import {
	AttachmentFile,
	AttachmentFileType,
	FileExtension,
	FileId,
	FileType,
	UploadedFile,
} from "@inrev/common";
import { useEffect, useMemo, useState } from "react";
import { HiCheck } from "react-icons/hi2";
import { QueryKey } from "react-query";
import { v4 } from "uuid";
import { UseUpload, useAttachmentUpload, useFileUpload } from "../../domain/agent/request/api";
import { getNameAndExtensionFromFileName } from "../../utils";
import { Modal } from "../layout/Modal";
import { cn } from "../lib/utils";
import { Button } from "./Button";
import { CheckboxOption } from "./CheckboxOption";
import { Dropzone } from "./Dropzone";
import { FileUploadItem, PendingFileUploadItem } from "./FileUploadItem";
import { RadioOption } from "./RadioOption";

export type UploadProps<
	TFileType extends FileType = FileType,
	T = UploadedFile<TFileType>,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = {
	files: UploadedFile<TFileType>[];
	useUpload: UseUpload<T, TFileType>;
	onUpload: (value: T) => void;
	onDelete: (id: string) => void;
	onDownload?: (id: string) => void;
	onBlur?: () => void;
	allowedTypesAndLabels: TAllowedTypesAndLabels;
	allowedExtensions?: FileExtension[];
	requiredTypes?: TRequired;
	typeQuestionText?: string;
	maxTypesPerFile?: number;
	subsequentFileTypeRestriction?: true;
	maxFiles?: number;
	hideChecklist?: true;
	showErrors?: boolean;
	preventDelete?: boolean;
	className?: string;
};

const Upload = <
	TFileType extends FileType = FileType,
	T = UploadedFile<TFileType>,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>({
	files,
	useUpload,
	onUpload,
	onDelete,
	onDownload,
	onBlur,
	allowedTypesAndLabels,
	allowedExtensions,
	requiredTypes,
	typeQuestionText,
	maxTypesPerFile,
	subsequentFileTypeRestriction,
	maxFiles,
	showErrors,
	hideChecklist,
	preventDelete,
	className,
}: UploadProps<TFileType, T, TAllowedTypesAndLabels, TAllowedType, TRequired>) => {
	const [filesPendingUpload, setFilesPendingUpload] = useState<UploadedFile<TFileType>[]>([]);
	const [fileIdsPendingDelete, setFileIdsPendingDelete] = useState<string[]>([]);
	const [showTypeSelection, setShowTypeSelection] = useState<boolean>(false);
	const [selectedTypes, setSelectedTypes] = useState<TFileType[]>([]);
	const [fileForTypeSelection, setFileForTypeSelection] = useState<File | undefined>();
	const [uploadModalError, setUploadModalError] = useState<string | undefined>();
	const allowedTypes = useMemo(() => {
		const types = Object.keys(allowedTypesAndLabels);
		if (!types.length) throw new Error();
		return types as [TFileType, ...TFileType[]];
	}, [allowedTypesAndLabels]);
	const atLeastOneSelectedType = (
		selectedTypes: TFileType[],
	): selectedTypes is [TFileType, ...TFileType[]] => {
		return selectedTypes.length > 0;
	};
	const requiredTypesFulfilled = useMemo(() => {
		if (!requiredTypes?.length) return true;
		return requiredTypes.every((requiredType) =>
			files.some((file) => file.types.includes(requiredType)),
		);
	}, [requiredTypes, files]);
	const { uploadFn, deleteFn } = useUpload(
		(data, args) => {
			setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
			onUpload(data);
		},
		(error, args) => {
			console.error(error);
			setFilesPendingUpload(filesPendingUpload.filter((file) => file.id !== args.pendingFile.id));
		},
		(args) => {
			onDelete(args.id);
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
			onBlur && onBlur();
		},
		(error, args) => {
			console.error(error);
			setFileIdsPendingDelete(fileIdsPendingDelete.filter((id) => id !== args.id));
		},
	);

	const handleUpload = (file: File) => {
		const { fileName, extension } = getNameAndExtensionFromFileName(file.name);
		if (allowedTypes.length === 1) {
			const uploadArgs = {
				rawFile: file,
				pendingFile: { id: v4() as FileId, name: fileName, extension, types: allowedTypes },
			};
			setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
			uploadFn(uploadArgs);
		} else if (subsequentFileTypeRestriction && (files.length || filesPendingUpload.length)) {
			const uploadArgs = {
				rawFile: file,
				pendingFile: {
					id: v4() as FileId,
					name: fileName,
					extension,
					types: [...files, ...filesPendingUpload][0].types,
				},
			};
			setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
			uploadFn(uploadArgs);
		} else {
			setFileForTypeSelection(file);
		}
	};

	const handleDelete = (id: string) => {
		if (!preventDelete) {
			setFileIdsPendingDelete([...fileIdsPendingDelete, id]);
			deleteFn({ id });
		}
	};

	const handleUploadTypeSelection = () => {
		try {
			if (!atLeastOneSelectedType(selectedTypes)) throw new Error("Must select at least one type");
			if (!fileForTypeSelection) throw new Error("No file to upload");
			const { fileName, extension } = getNameAndExtensionFromFileName(fileForTypeSelection.name);
			const uploadArgs = {
				rawFile: fileForTypeSelection,
				pendingFile: { id: v4() as FileId, name: fileName, extension, types: selectedTypes },
			};
			setFilesPendingUpload([...filesPendingUpload, uploadArgs.pendingFile]);
			uploadFn(uploadArgs);
			setShowTypeSelection(false);
		} catch (error) {
			if (error instanceof Error) setUploadModalError(error.message);
		}
	};

	useEffect(() => {
		onBlur && onBlur();
	}, [files]);

	useEffect(() => {
		if (fileForTypeSelection !== undefined) setShowTypeSelection(true);
	}, [fileForTypeSelection]);

	useEffect(() => {
		if (!showTypeSelection) {
			if (selectedTypes !== undefined) setSelectedTypes([]);
			if (fileForTypeSelection !== undefined) setFileForTypeSelection(undefined);
		}
	}, [showTypeSelection]);

	useEffect(() => {
		if (selectedTypes.length > 0) setUploadModalError(undefined);
	}, [selectedTypes]);

	return (
		<div className={cn("relative flex flex-col space-y-[25px] w-full", className)}>
			{requiredTypes && !!requiredTypes.length && !hideChecklist && (
				<div
					className={cn(
						"relative flex-1 flex flex-col space-y-[5px] pl-[23px] after:absolute after:w-[3px] after:rounded-full after:bg-gray-100 after:top-0 after:bottom-0 after:left-0",
						showErrors && !requiredTypesFulfilled ? "after:bg-red-100" : undefined,
					)}
				>
					{requiredTypes.map((type) => {
						const typeIncluded = files.some((file) => file.types.includes(type));
						return (
							<div
								key={type}
								className={cn(
									"flex items-center space-x-[10px] text-[15px]",
									typeIncluded ? "opacity-100" : "opacity-70",
									showErrors && !typeIncluded ? "text-red-500 opacity-100" : undefined,
								)}
							>
								<div
									className={cn(
										"relative aspect-square h-4 w-4 rounded-full border border-gray-400 bg-white focus:outline-none flex items-center justify-center",
										showErrors && !typeIncluded ? "border-red-500" : undefined,
									)}
								>
									{typeIncluded && (
										<div className="flex items-center justify-center absolute top-[-1px] left-[-1px] aspect-square h-4 w-4 rounded-full bg-inrev-light-blue ml-[-1] mt-[-1]">
											<HiCheck className="text-[10px] text-white stroke-[4px]" />
										</div>
									)}
								</div>
								<div>{allowedTypesAndLabels[type]}</div>
							</div>
						);
					})}
				</div>
			)}
			<div className="w-full flex flex-col space-y-[15px]">
				{showErrors &&
					!requiredTypesFulfilled &&
					hideChecklist &&
					requiredTypes &&
					!!requiredTypes.length && <div className="text-[13px] text-red-500">Required</div>}
				{(maxFiles === undefined || files.length + filesPendingUpload.length < maxFiles) && (
					<Dropzone allowedExtensions={allowedExtensions} onSuccess={handleUpload} />
				)}
				{(!!files.length || !!filesPendingUpload.length) && (
					<div className="w-full max-w-full flex flex-col space-y-[10px] cursor-default">
						{files.map((file) => (
							<FileUploadItem
								key={file.id}
								{...file}
								typeLabelMap={allowedTypesAndLabels}
								preventDelete={preventDelete}
								onDelete={handleDelete}
								deleting={fileIdsPendingDelete.includes(file.id)}
								onDownload={onDownload}
							/>
						))}
						{filesPendingUpload.map((file) => (
							<PendingFileUploadItem key={file.id} {...file} typeLabelMap={allowedTypesAndLabels} />
						))}
					</div>
				)}
			</div>
			{showTypeSelection && (
				<Modal onClickOutside={() => setShowTypeSelection(false)}>
					<div
						className={cn(
							"py-[35px] px-[35px] bg-white flex flex-col justify-center rounded-md shadow-lg space-y-[15px]",
							className,
						)}
					>
						<div className="text-[16px] text-gray-600">
							{typeQuestionText ?? "What type of file is this?"}
							{uploadModalError && (
								<div className="text-[13px] text-red-500 mt-2">{uploadModalError}</div>
							)}
						</div>
						{maxTypesPerFile === 1 && (
							<RadioGroup
								value={selectedTypes[0]}
								onChange={(value) => setSelectedTypes([value])}
								className="flex flex-col space-y-[15px]"
							>
								{allowedTypes.map((type) => (
									<RadioOption
										value={type}
										onSelect={() => setSelectedTypes([type])}
										label={allowedTypesAndLabels[type]}
										key={type}
									/>
								))}
							</RadioGroup>
						)}
						{maxTypesPerFile !== 1 &&
							allowedTypes.map((type) => {
								const checked = selectedTypes.includes(type);
								return (
									<CheckboxOption
										value={type}
										checked={checked}
										onChange={(updateChecked) =>
											setSelectedTypes(
												!updateChecked
													? selectedTypes?.filter((selectedType) => selectedType !== type)
													: [...selectedTypes, type],
											)
										}
										label={allowedTypesAndLabels[type]}
										key={type}
									/>
								);
							})}
						<Button
							color="light-blue"
							className="!mt-[25px]"
							filled
							onClick={handleUploadTypeSelection}
						>
							Upload
						</Button>
					</div>
				</Modal>
			)}
		</div>
	);
};

export type FileUploadProps<
	TFileType extends FileType = FileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = Omit<
	UploadProps<TFileType, UploadedFile<TFileType>, TAllowedTypesAndLabels, TAllowedType, TRequired>,
	"files" | "useUpload" | "onUpload" | "onDelete"
> & {
	value: UploadedFile<TFileType>[];
	onChange: (value: UploadedFile<TFileType>[]) => void;
};

export const FileUpload = <
	TFileType extends FileType = FileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>(
	props: FileUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>,
) => {
	const { value, onChange, ...rest } = props;
	const onUpload = (file: UploadedFile<TFileType>) => onChange([...value, file]);
	const onDelete = (id: string) => onChange(value.filter((file) => file.id !== id));

	return (
		<Upload
			{...rest}
			files={value}
			onUpload={onUpload}
			onDelete={onDelete}
			useUpload={useFileUpload}
		/>
	);
};

export type AttachmentUploadProps<
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
> = Omit<
	UploadProps<
		TFileType,
		AttachmentFile<TFileType>,
		TAllowedTypesAndLabels,
		TAllowedType,
		TRequired
	>,
	"files" | "useUpload" | "onUpload" | "onDelete"
> & {
	value: AttachmentFile<TFileType>[];
	onChange: (value: AttachmentFile<TFileType>[]) => void;
	onDownload?: (id: string) => void;
	url: `${string}/attachments`;
	invalidateQueryKeys?: QueryKey[];
};

export const AttachmentUpload = <
	TFileType extends AttachmentFileType = AttachmentFileType,
	TAllowedTypesAndLabels extends Partial<Record<TFileType, string>> = Partial<
		Record<TFileType, string>
	>,
	TAllowedType extends Extract<TFileType, keyof TAllowedTypesAndLabels> = Extract<
		TFileType,
		keyof TAllowedTypesAndLabels
	>,
	TRequired extends TAllowedType[] | undefined = TAllowedType[] | undefined,
>(
	props: AttachmentUploadProps<TFileType, TAllowedTypesAndLabels, TAllowedType, TRequired>,
) => {
	const { value, onChange, url, ...rest } = props;
	const onUpload = (attachment: AttachmentFile<TFileType>) => onChange([...value, attachment]);
	const onDelete = (id: string) => onChange(value.filter((attachment) => attachment.id !== id));
	const attachmentFiles = useMemo(
		() =>
			value.map((attachment) => ({ ...attachment.file, id: attachment.id as unknown as FileId })),
		[value],
	);

	return (
		<Upload
			{...rest}
			files={attachmentFiles}
			onUpload={onUpload}
			onDelete={onDelete}
			useUpload={useAttachmentUpload<TFileType>(url, props.invalidateQueryKeys)}
			onDownload={props.onDownload}
		/>
	);
};
